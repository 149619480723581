@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes neonPulse {
  0% {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }

  10% {
    box-shadow: 0 0 8px #39ff14, 0 0 16px #39ff14, 0 0 24px #39ff14, 0 0 32px #39ff14;
  }

  30% {
    box-shadow: 0 0 12px #39ff14, 0 0 20px #39ff14, 0 0 28px #39ff14, 0 0 36px #39ff14;
  }

  50% {
    box-shadow: 0 0 6px #39ff14, 0 0 12px #39ff14, 0 0 18px #39ff14, 0 0 24px #39ff14;
  }

  70% {
    box-shadow: 0 0 10px #39ff14, 0 0 18px #39ff14, 0 0 26px #39ff14, 0 0 34px #39ff14;
  }

  90% {
    box-shadow: 0 0 14px #39ff14, 0 0 22px #39ff14, 0 0 30px #39ff14, 0 0 38px #39ff14;
  }

  100% {
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
}